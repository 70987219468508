import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/Layout';
import useAuth from '../util/hooks/useAuth';
import { ScrollContainer } from '../context/scrollBehavior';
import SEO from '../components/SEO';
import ExhibitionCard from '../components/global/ExhibitionCard';

// Exhibition return block only for exhibition landing page
const Exhibition = ({ items, block_title }, device) => {
  return (
    <div>
      {items && items.length ? (
        <section className="grid-content-container t-left">
          <a
            className="anchor"
            id={block_title}
            style={{
              visibility: 'hidden',
              position: 'relative',
              top: '-200px',
            }}
          />
          <div className="t-heading-container">
            <h2 className="t-heading t-uppercase"> {block_title}</h2>
          </div>
        </section>
      ) : (
        <section className="grid-section grid-content-container t-left">
          <a
            className="anchor"
            id={block_title}
            style={{
              visibility: 'hidden',
              position: 'relative',
              top: '-200px',
            }}
          />
          <div className="t-heading-container">
            <h2 className="t-heading t-uppercase"> {block_title}</h2>
            <h4 className="t-medium t-subheading t-uppercase exh-date no-exhibs">
              There are no {block_title}
            </h4>
          </div>
        </section>
      )}
      {items && items.length
        ? items.map(({ itemNode: exhibition }) => {
            if (!exhibition.title) {
              return null;
            } else {
              return (
                <section
                  key={exhibition.title}
                  className="grid-section grid-content-container exh-block"
                >
                  <ExhibitionCard
                    exhibition={exhibition}
                    device={device}
                    viewButton={true}
                  />
                </section>
              );
            }
          })
        : null}
    </div>
  );
};

const ExhibitionsPage = ({ data }) => {
  useAuth();
  const landingPage = data.DrupalParagraphs.edges[0].node;
  const { title, field_json_api_call_data } = landingPage;

  const [device, setDevice] = useState('mobile');

  const subMenu = { title: 'exhibitions', extra: true };

  return (
    <>
      <SEO title="Exhibitions" />
      <Layout
        device={device}
        setDevice={setDevice}
        logo={data.logo}
        menu={data.allMenuMenuLink}
        subMenu={subMenu}
        footerMenu={data.footer_menu}
        locations={data.locations.edges}
      >
          <section className="grid-section grid-section-exhib grid-content-container">
            {field_json_api_call_data.map((block, i) => {
              return (
                <ScrollContainer>
                  <Exhibition {...block} device={device} title={title} />
                </ScrollContainer>
              );
            })}
          </section>
      </Layout>
    </>
  );
};

export default ExhibitionsPage;

export const IndexQuery = graphql`
  query exhibitionsQuery {
    DrupalParagraphs: allNodeLandingPage(
      filter: { title: { eq: "Exhibitions" } }
    ) {
      edges {
        node {
          title
          id
          field_json_api_call_data {
            type
            block_title
            url
            form
            description
            additional_info {
              content_type
            }
            items {
              itemNode {
                title
                relationships {
                  field_featured_image {
                    uri {
                      url
                    }
                    image_style_uri {
                      featured_image_crop
                    }
                  }
                  field_locations_ref {
                    title
                  }
                }
                path {
                  alias
                }
                field_dates {
                  end_value
                  value
                }
              }
            }
          }
        }
      }
    }
    allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "gatsby-menu" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          drupal_parent_menu_item
          bundle
          drupal_id
          menu_name
        }
      }
    }
    footer_menu: allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "footer" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          bundle
          drupal_id
          menu_name
        }
      }
    }
    locations: allNodeLocation {
      edges {
        node {
          field_address {
            address_line1
            address_line2
            country_code
            locality
            postal_code
          }
          field_address_description
          field_address_short
          field_location_email
          field_location_fax_number
          field_location_phone_number
          title
          field_office_hours_description
        }
      }
    }
  }
`;
